<script lang="ts" setup>
const route = useRoute()

const lists = [
  {
    title: 'Links',
    items: [
      {
        text: 'About Us',
        link: '/about',
      },
      {
        text: 'Schedule',
        link: '/schedule',
      },
      {
        text: 'Rules',
        link: '/rules',
      },
      /* {
        text: 'Leaderboard',
        link: '/leaderboard',
      },
      {
        text: 'Raffle',
        link: '/raffles',
      }, */
      {
        text: 'Sponsors',
        link: '/#sponsors',
      },
      {
        text: 'Contact Us',
        link: 'mailto:contact@borrachopescador.org',
      },
      {
        text: 'Terms of Service',
        link: '/terms',
      },
      {
        text: 'Privacy Policy',
        link: '/privacy',
      },
    ],
  },
  {
    title: 'Mailing Address',
    items: [
      {
        text: '2517 Boyd Ave.',
      },
      {
        text: 'Fort Worth, Texas 76109',
      },
      {
        text: 'contact@borrachopescador.org',
        link: 'mailto:contact@borrachopescador.org',
      },
    ],
  },
  {
    title: 'Follow Us',
    type: 'social',
    items: [
      {
        icon: 'fa:instagram',
        link: 'https://www.instagram.com/borrachopescador/',
      },
      {
        icon: 'fa-brands:facebook',
        link: 'https://www.facebook.com/borrachopescador/',
      },
    ],
  },
]

const style = computed(() => ({
  wrapper: [
    'text-left border-t-border py-7',
    'lg:pt-15 lg:pb-15',
    {
      'border-t': route.fullPath !== '/',
    },
  ],
  container: [
    'flex flex-col items-start justify-between',
    'lg:flex-row lg:flex-wrap',
  ],
  right: [
    'order-first mb-7 w-full',
    'md:mb-10',
    'lg:mb-0 lg:w-auto lg:order-none',
  ],
  lists: [
    'flex flex-wrap gap-x-20 gap-y-6',
    'lg:gap-24 lg:items-start',
    'xl:gap-35',
  ],
  withLove: 'mt-3 lg:mt-15 w-full text-sm lg:text-base lg:text-center',
}))
</script>

<template>
  <footer
    :class="style.wrapper"
  >
    <CommonContainer :class="style.container">
      <div>
        <p class="text-sm">
          © Borracho Pescador, 2024
        </p>
        <IconsLogoHopescador class="h-15 mt-5 hidden lg:block" />
      </div>

      <div :class="style.right">
        <div :class="style.lists">
          <!-- Section -->
          <div v-for="list in lists" :key="list.title">
            <h2 class="font-accent text-2xl text-t-text uppercase tracking-wider mb-3 lg:mb-5 font-medium">
              {{ list.title }}
            </h2>

            <ul v-if="list?.type === 'social'" class="flex flex-wrap gap-4">
              <li v-for="item in list.items" :key="item.link">
                <a :href="item.link" target="_blank" class="hover:text-t-text transition-colors">
                  <Icon :name="item.icon" class="w-6 h-6" />
                </a>
              </li>
            </ul>

            <ul v-else class="text-sm leading-relaxed grid gap-1.5">
              <li v-for="item in list.items" :key="item.text">
                <span v-if="!item.link">{{ item.text }}</span>
                <NuxtLink v-else :to="item.link" class="hover:text-t-text transition-colors">
                  {{ item.text }}
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Made with Love -->
      <p :class="style.withLove">
        Made with <Icon name="ion:heart-sharp" class="w-6 h-6 animate-heartbeat" /> by our friends at DialedN
      </p>
    </CommonContainer>
  </footer>
</template>
